<template>
  <div class="login-wrap">
    <div class="login_banner">
      <img src="https://beinabncc.oss-cn-beijing.aliyuncs.com/zhengshu//bn-java-bj20201125.jpg"/>
    </div>
    <ul class="title-ul">
      <li data-type="tel">
        您的好友<span style="color:#df0024;">&nbsp;{{userName}}&nbsp;</span>邀请您加入BNCC会员
        <span style="display: block">注册或登录成功即可获得200元优惠券</span>
      </li>
    </ul>
    <div class="form-wapper">
      <div class="form">
        <div class="form-item">
          <label for="tel">
            <div class="form-label">
              <input
                  placeholder="请输入手机号"
                  id="tel"
                  ref="tel"
                  v-model="telphone"
                  name="tel"
                  type="text"
                  autocomplete="off"
              />
            </div>
          </label>
        </div>

        <div class="form-item code-pic">
          <label for="code">
            <div class="form-label code-form-label">
              <input
                  placeholder="请输入图形验证码"
                  id="passCode"
                  v-model="passCode"
                  name="passCode"
                  maxlength="4"
                  autocomplete="off"
              />
            </div>
          </label>
          <img :src="codeImg" @click="getCodeImg" alt />
        </div>

        <div class="form-item code-item">
          <label for="code">
            <div class="form-label code-form-label">
              <input
                  placeholder="请输入验证码"
                  id="code"
                  v-model="code"
                  name="code"
                  type="number"
                  maxlength="6"
                  @input="changeNum"
              />
            </div>
          </label>
          <button
              id="getCode"
              @click="getSMSCode"
              v-if="codeText == '获取验证码'"
          >
            {{ codeText }}
          </button>
          <button id="getCode" v-else>{{ codeText }}</button>
        </div>
      </div>

      <div :class="{ 'submit-wapper': true }">
        <div
            id="code-login"
            :class="{ submit: true,  }"
            @click="handleCodeLogin"
        >
          登录
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  inject,
} from "vue";
import Toast from "@/components/UI/Toast";
import api from "@/api/axios";
import { useRouter, useRoute } from "vue-router";
import Modal from "@/components/UI/Modal";
export default defineComponent({
  name: "Invitation",
  emits: ["loginsuccess"],
  setup(props, ctx) {

    const router = useRouter();
    const route = useRoute();
    const invitationCode = ref(route.query.invitationCode);
    const userName = ref("");
    console.log('invitationCode',invitationCode.value)
    //code 登录
    const telphone = ref<string>("");
    const code = ref<string>("");
    const passCode = ref<string>("");
    const passwordNew = ref<string>("");
    const sendCodeFlag = ref<boolean>(true);
    const codeText = ref<string>("获取验证码");
    let timer: any = ref(null);
    const count = ref(60);
    api
        .get("/M/User/getUserName?userId="+invitationCode.value)
        .then((res) => {
          if (res.data.success) {
            userName.value = res.data.obj;
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log('err')
        });







    //手机号校验
    function checkTel(tel: string) {
      if (tel === "") {
        Toast({
          title: "请输入手机号码",
          type: "warning",
        });
        return false;
      }
      const retel = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
      if (tel.length != 11 || !retel.test(tel)) {
        Toast({
          title: "请输入正确的手机号码",
          type: "warning",
        });
        // $('#tel').focus();
        return false;
      }
      return true;
    }

    //获取验证码
    function getSMSCode() {
      if (!checkTel(telphone.value)) {
        return false;
      }
      if (!passCode.value) {
        Toast({
          title: "请输入图形验证码",
          type: "warning",
        });
        return false;
      }
      if( !sendCodeFlag.value ){
        return false
      }
      sendCodeFlag.value = false;
      //获取验证码
      const formData = new FormData();
      formData.append("tel", telphone.value);
      formData.append("option", "registerOpt");
      formData.append("passcode", passCode.value);
      api
          .post("/M/Server/GetNewSMSCode", formData)
          .then((res) => {
            if (res.data.success) {
              Toast({
                title: "验证码发送成功，请注意查收",
                type: "success",
              });
              count.value = 60;
              codeText.value = count.value + "s";
              timer = setInterval(function () {
                count.value--;
                if (count.value == 0) {
                  clearInterval(timer);
                  sendCodeFlag.value = true;
                  codeText.value = "获取验证码";
                } else {
                  codeText.value = count.value + "s";
                }

              }, 1000);
            } else {
              sendCodeFlag.value = true;
              Toast({
                title: res.data.msg,
                type: "error",
              });
            }
          })
          .catch(() => {
            Toast({
              title: '未知错误，请联系客服',
              type: "success"
            })
          });
    }

    //  登录成功处理
    const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }
    function handleUserInfo(data: object) {
      UPDATE_USERINFO(data);
    }
    //获取图形验证码
    const codeImg = ref("/M/Server/GetVerifyCodeImg");
    function getCodeImg() {
      codeImg.value = "/M/Server/GetVerifyCodeImg?random=" + Math.random();
    }

    //注册提交方法
    function handleCodeLogin() {
      const formData = new FormData();
      // formData.append("invitationCode", '1385417619640823810');
      formData.append("invitationCode", invitationCode.value as any);
      formData.append("userName", telphone.value);
      formData.append("smsCode", code.value);
      formData.append("passCode", passCode.value);//图形验证码
      // formData.append("rePassword", passwordNew.value);
      api.post("/M/Server/doRegisterOfInvitation", formData)
          .then((res) => {
            if (res.data.success) {
              updateToken(res.data.token);
              handleUserInfo(res.data.obj);
              Toast({
                title: "注册成功！",
                type: "success",
              });
              // if (String(res.data.msg).trim() == "请设置密码!") {
              //     ctx.emit("loginsuccess");
              // }
              ctx.emit("loginsuccess");
              const redirect = (route.query.redirect ? route.query.redirect : "/mine") as string;
              router.push(redirect)
            } else {
              if(res.data.msg =='您已被邀请，不可重复操作'){
                Modal({
                  title: "温馨提示",
                  content: "您已被邀请，不可重复操作",
                  confirmText: '前往登录',
                  onConfirm: () => {
                    console.log("sdfsdfsd")
                    router.push({
                      name: "Login"
                    });
                  },
                  onClose: () => {
                    console.log('取消')
                  },
                });
                return false;
              }else{
                Toast({
                  title: res.data.msg,
                  type: "error",
                });
              }

            }
          }).catch(() => {
        Toast({
          title: "提交异常，请联系客服",
          type: "error",
        });
      });
    }

    function changeNum() {
      if(code.value.length > 6){
        code.value = code.value.slice(0, 6)
      }
    }

    return {
      telphone,
      code,
      codeText,
      invitationCode,
      getSMSCode,
      handleCodeLogin,
      changeNum,
      codeImg,
      getCodeImg,
      passCode,
      passwordNew,
      userName
    };
  },
});
</script>

<style lang="scss" scoped>
.login_banner img {
  width: 10rem;
}

.title-ul {
  overflow: hidden;
}

.title-ul li {
  position: relative;
  width: 100%;
  //height: 0.867rem;
  line-height: 0.867rem;
  text-align: center;
  /*color: #999999;*/
  font-size: 0.40rem;
  font-weight: bold;
}

.title-ul .active {
  color: #444;
}

.form-wapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.267rem;
  padding-top: 0.773rem;
}
.form-item{
  border-bottom: 1px solid #eee;
  margin-bottom: 0.25rem;
}

.form-label {
  width: 100%;
  height: 0.9rem;
  line-height: 0.9rem;
  border-radius: 0.067rem;
  padding-left: 0.4rem;
  box-sizing: border-box;
}

.form-label input {
  outline: none;
  border: none;
  width: 100%;
  height: 0.9rem;
  line-height: 0.9rem;
  vertical-align: top;
  background-color: transparent;
  font-size: 0.373rem;
}

.code-item {
  position: relative;
  padding-right: 3.467rem;
  box-sizing: border-box;
}
.code-item button {
  position: absolute;
  right: 0;
  top: 0.01rem;
  width: 2.4rem;
  height: 0.8rem;
  border-radius: 0.067rem;
  border: solid 0.027rem #d64b4b;
  line-height: 0.8rem;
  text-align: center;
  color: white;
  font-size: 0.373rem;
  background: #d64b4b;
  outline: none;
  display: block;
}

.code-pic {
  position: relative;
  padding-right: 3.467rem;
  box-sizing: border-box;
}

.code-pic img {
  position: absolute;
  right: 0;
  top: 0.01rem;
  width: 2.4rem;
  height: 0.8rem;
  border-radius: 0.067rem;
  // border: solid 0.027rem #d64b4b;
  line-height: 0.8rem;
  text-align: center;
  color: #d64b4b;
  font-size: 0.373rem;
  background: none;
  outline: none;
  display: block;
}

.submit {
  margin-top: 0.987rem;

  display: block;
  width: 100%;
  height: 1.2rem;
  background-color: #d64b4b;
  box-shadow: 0 0.133rem 0.267rem 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.133rem;
  line-height: 1.2rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.4rem;
  margin-top: 1rem;
}

.forget-password {
  margin-top: 0.267rem;
  color: red;
  float: right;
  margin-left: 0.533rem;
  font-size: 0.32rem;
}
.login-wrap {
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  height: 100%;
  width: 100%;
  padding-top: 55px;
}
.login-transform {
  transform: translateX(0);
}

.weixin-login {
  width: 45%;
}
.submit-weixin {
  display: flex;
  justify-content: space-between;
}

.login-enter-active,
.login-leave-active {
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login-enter-from,
.login-leave-to {
  transform: translateX(100%);
}
</style>
